<template>
  <div class="energy">
    <div class="back">
      <img :src="require('../assets/img/back2.png')" width="100%" height="100%" @click="back"/>
    </div>
    <div class="title p-l-30 p-r-30 white">{{user.stone}}</div>
    <div class="f-12 p-l-30 p-r-30">我的元石</div>
<!--    <van-icon name="clear" class="close"/>-->
    <div class="logo m-t-25">
      <img :src="require('../assets/img/tone.png')" width="100%" height="100%"/>
    </div>
    <div class="p-l-30 p-r-30">
      <van-swipe class="my-swipe" :loop="false" indicator-color="rgba(0, 0, 0, .3)">
        <van-swipe-item>
          <div class="item">
            <div class="m-b-10 bold"><van-icon name="warning-o"/>&nbsp;&nbsp;元石</div>
            <div>
              轻松小镇内部推出的两种活动积分之一，数量不限，可用于兑换积分盲盒、为艾草种子施肥等小镇未来游戏玩法。目前可通过完成邀请好友、签到等任务获得，未来可用于兑换相关服务和道具。
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="item full-height" style="overflow: scroll">
            <div>
              <span class="grey f-12" :class="type === 1 ? 'active' : ''"
                    @click="checkType(1)">收&nbsp;|&nbsp;</span>
              <span class="grey f-12" :class="type === 2 ? 'active' : ''"
                    @click="checkType(2)">支</span>
            </div>
            <template v-if="list.length">
              <div class="list" v-for="(item, index) in list" :key="index">
                <div class="up m-b-10 bold-500">
                  <span>{{item.state}}</span>
                  <span class="f-18">{{item.type === 1 ? '+' : '-'}}{{item.quantity}}</span>
                </div>
                <div class="grey f-12">{{item.time}}</div>
              </div>
            </template>
            <div v-else class="empty">
              <span>暂无记录</span>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { stoneApi } from '../api/user'
import moment from 'moment'
import { mapState } from 'vuex'
import { getWxConfig } from '../../tool'

export default {
  name: 'Enery',
  data () {
    return {
      list: [],
      type: 1
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created () {
    this.getStoneList()
    getWxConfig('轻松小镇', '元石记录')
  },
  activated () {
    this.getStoneList()
  },
  methods: {
    back () {
      this.$router.back()
    },
    checkType (type) {
      this.type = type
      this.list = []
      this.getStoneList()
    },
    async getStoneList () {
      try {
        const res = await stoneApi({
          type: this.type
        })
        res.data.storelog.forEach(item => {
          item.time = moment(new Date(item.create_time * 1000)).format('YYYY.MM.DD HH:mm')
        })
        this.list = res.data.storelog
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .energy{
    height: 120vh;
    padding-top: 48px;
    color: white;
    position: relative;
    background-color: black;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    .back{
      position: absolute;
      left: 17px;
      top: 17px;
      width: 27px;
      height: 27px;
      border-radius: 50%;
    }
    .title{
      font-size: 43px;
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 21px;
      height: 333px;
      margin-top: -30px;
    }

    .my-swipe{
      margin-top: -50px;
      height: 275px;
      box-sizing: content-box;
      padding-bottom: 35px;
      .item{
        height: 100%;
        padding: 42px 45px;
        background-image: url("../assets/img/energy-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 25px;
        overflow: hidden;
        .active{
          font-size: 19px;
          color: white;
        }
        .list{
          margin-top: 20px;
          .up{
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .empty{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 60px 10px;
        }
      }
    }
  }
</style>
